import { testWhatsAppMessage } from "../constants/events";
import { formatPhoneForWhatsapp, OpenWatsApp } from "../utils/device";

export const sendWhatsAppEventMessage = function(student, event, user) {
  let _text = `
  שלום לך, `+student.firstName + ' ' + student.lastName+`,
  ברצוני להזכירך כי בתאריך `+event.start.getDate() + '/' + (event.start.getMonth() + 1).toString() + '/' + event.start.getFullYear()+`, ב`+new Date(event.start).toLocaleString('he-il', {weekday:'long'})+`,
  בשעה `+event.start.getHours() +':'+event.start.getMinutes()+`
  קבענו שיעור.
  נתראה`
  if (event.eventType == 2 || event.eventType.value == 2) _text = testWhatsAppMessage({...event, firstName: student.firstName, lastName: student.lastName }, user)
  const phone = student.phone.startsWith("0") ? student.phone.substring(1) : student.phone
  OpenWatsApp(formatPhoneForWhatsapp(phone), _text)
}

export const eventStatusIcons = {
  0: "icons8-question-mark",
  1: "icons8-checkmark",
  2: "icons8-shekel",
  3: "icons8-cancel",
  4: "icons8-cancel-with-usd",
};

export const getNameStyle = (studentData) => {
  let styles = {};
  if (studentData.internalTestStatus) {
    styles.borderTop = "1px solid black";
  }
  if (
    studentData.conversion ||
    studentData.refresh ||
    studentData.renewal ||
    studentData.control
  ) {
    styles.borderLeft = "1px solid black";
    styles.borderRight = "1px solid black";
  }
  if (
    studentData.fundingBody !== 999 &&
    studentData.fundingBody !== null &&
    typeof studentData.fundingBody !== "undefined"
  ) {
    styles.borderBottom = "1px solid black";
  }

  return styles;
};

export const drivingPermitsOptions = [
  { value: 1, label: "הכשרה" },
  { value: 2, label: "רענון נהיגה" },
  { value: 3, label: "מטרונית" },
  { value: 4, label: "מפרקית" },
  { value: 5, label: "אוטובוס חשמלי" },
  { value: 6, label: "אוטובוס" },
  { value: 7, label: "אוטובוס בינערוני" },
  { value: 8, label: "מיניבוס" },
  { value: 9, label: "הסבה לקו" },
];

export const testStatus = [
  { icon: "icons8-question", text: "בהזמנה" },
  { icon: "icons8-happy", text: "עבר" },
  { icon: "icons8-crying", text: "נכשל" },
];

export const internalTestStatus = [
  { icon: "icons8-question", text: "בהזמנה" },
  { icon: "icons8-happy", text: "עבר" },
  { icon: "icons8-crying", text: "נכשל" },
];

export const isTestEvent = (eventData, onlyActive = true) => {
  if (eventData.eventType != 2) return false;
  if (!onlyActive) return true;
  return eventData.eventStatus != 3 && eventData.eventStatus != 4;
}

export const isInternalTestEvent = (eventData, onlyActive = true) => {
  if (eventData.eventType != 3) return false;
  if (!onlyActive) return true;
  return eventData.eventStatus != 3 && eventData.eventStatus != 4;
}