import React, { useState } from "react";
import {
  Container,
  Icon,
  TitleContainer,
  TextAfterIcon,
  IconsAndTextContainer,
} from "./eventView.styles";
import moment from "moment";
import { connect } from "react-redux";
import EditEvent from "../EditEvent/EditEvent";
import ReactTooltip from "react-tooltip";
import { isMobile, isTablet } from "react-device-detect";
import { getStudentIncomes } from "../../../actions/incomesAction";
import useTeacheres from "../../useComponents/useTeachers";
import { getUsers } from "../../../actions/userActions";
import { getAgreementDetails } from "../../../actions/agreementsActions";
import {
  getStudentEvents,
  getStudentEventCount,
  getStudentData,
} from "../../../actions/schedulerActions";
import {
  getStudentTextBooks,
  fetchTextBook,
} from "../../../actions/textBooksActions";
import { sendWhatsAppEventMessage, eventStatusIcons, getNameStyle, drivingPermitsOptions } from "../../../utils/events";

const EventView = (event) => {
  const [isEditLessonModalOpen, setIsEditLessonModalOpen] = useState(false);
  const [studentEventCount, setStudentEventCount] = useState(0);
  const [displayBalance, setDisplayBalance] = useState(false);
  const [balance, setBalance] = useState(0);
  const [studentProfile, setStudentProfile] = useState(null);

  const [isShowCount, setIsShowCount] = useState(false);
  const teachersOptions = useTeacheres(
    event.isUsersLoaded,
    event.getUsers,
    event.users
  );

  const getEventTypeIcon = (eventType) => {
    switch (eventType) {
      case 1:
        return <Icon className="icons8-steering-wheel" style={{ margin: 0 }} />;
      case 2:
        return <Icon className="icons8-graduation-cap" />;
      case 3:
        return <Icon className="icons8-road" />;
      case 4:
        return <Icon className="icons8-tie" />;
      default:
        return "";
    }
  };
  const formatPhoneForWhatsapp = (phoneNumber) => {
    phoneNumber = phoneNumber.trim();
    phoneNumber = phoneNumber.replaceAll('-', '');
    if (phoneNumber.includes('+972')) return phoneNumber;
    else if (phoneNumber.includes('972')) return '+' + phoneNumber;
    else {
      return '+972' + phoneNumber;
    }
  }

  const loadStudentProfile = async () => {
    const profile = await event.loadStudentProfile(event.event);
    setStudentProfile(profile)
  }

  const getStudentEventCount = () => {
    if (!isShowCount) {
      if (studentProfile === null) {
        loadStudentProfile().then(() => {
          setIsShowCount(true);
        });
      } else {
        setIsShowCount(true);
      }
    } else {
      setIsShowCount(false);
    }
  };

  const setStudentBalance = () => {
    if (displayBalance) {
      setDisplayBalance(false);
    } else {
      loadStudentProfile().then(() => {
        setDisplayBalance(true);
      });
    }
  };

  return !isMobile && !isTablet ? (
    <Container
      dir="auto"
      isStudentChosen={
        typeof event.chosenStudentId !== "undefined" &&
        event.chosenStudentId === event.event.studentId
      }
    >
      <ReactTooltip place="top" type="light" effect="float" html={true} />
      {isEditLessonModalOpen && (
        <EditEvent
          isNewAgreementModalOpen={isEditLessonModalOpen}
          setNewAgreementModalIsOpen={setIsEditLessonModalOpen}
          step={event.eventTime}
          selectedEvent={{
            ...event.event,
            name: event.event.name
              ? event.event.name
              : `${event.event.studentId} - ${event.event.firstName} ${event.event.lastName}`,
          }}
        />
      )}
      <TitleContainer
        // onClick={() => setIsEditLessonModalOpen(true)}
        dir="auto"
        className="eventView"
        isDay={event.isMargin}
      >
        {event.showEventStatus ? (
          <IconsAndTextContainer
            onClick={() => event.openEventStatusChangeModal(event.event)}
            style={{ margin: 0 }}
          >
            <Icon
              className={eventStatusIcons[event.event.eventStatus]}
              style={{ margin: 0 }}
              aria-hidden="true"
            />
          </IconsAndTextContainer>
        ) : null}
        {event.showTime ? (
          <IconsAndTextContainer onClick={() => setIsEditLessonModalOpen(true)}>
            <Icon className="icons8-clock" />
            <TextAfterIcon>
              {Math.round((event.event.end - event.event.start) / 1000 / 60)}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showEventType ? (
          <IconsAndTextContainer onClick={() => getStudentEventCount()}>
            {getEventTypeIcon(event.event.eventType)}
            <TextAfterIcon>
              {isShowCount ? studentProfile.totalEventsForType : event.event.title}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showVehicle &&
        !isMobile &&
        typeof event.event.description === "string" ? (
          <IconsAndTextContainer
            onClick={() => event.openVehicleChange(event.event)}
          >
            <Icon
              className="icons8-car"
              data-tip={`<span dir="auto">${event.event.description}</span>`}
              data-for={event.event.id.toString()}
            />
            <TextAfterIcon>{event.event.description}</TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {/* <IconsAndTextContainer>
          <Icon className="fa fa-book" aria-hidden="true" />
          <TextAfterIcon>כרטסת</TextAfterIcon>
        </IconsAndTextContainer> */}
        {!isMobile &&
        typeof event.event.pickupLocationName === "string" &&
        event.event.pickupLocationName !== "ללא" ? (
          <IconsAndTextContainer
            onClick={() => event.openPickupModal(event.event.id)}
          >
            <Icon
              className="icons8-location"
              data-tip={`<span dir="auto">${event.event.pickupLocationName}</span>`}
              data-for={event.event.id.toString()}
            />
            <TextAfterIcon>{event.event.pickupLocationName}</TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showStudent && typeof event.event.firstName === "string" ? (
          <IconsAndTextContainer
            // onClick={() => event.openStudentMenuModal(event.event)}
            onClick={() =>
              event.onStudentClick({
                event: event.event,
                value: event.event.studentId,
                label: `${event.event.firstName} ${event.event.lastName}`,
              })
            }
          >
            {/*<Icon
              className="icons8-user-group-man-man"
              data-tip={`<span dir="auto">${event.event.firstName} ${event.event.lastName}</span><br/><span dir="auto">${event.event.address}, ${event.event.city}</span><br/><span dir="auto">${event.event.phone}</span>`}
              data-for={event.event.id.toString()}
            />*/}
            <Icon
              className="icons8-whatsapp"
              onClick={() => sendWhatsAppEventMessage(event.event, event.event, event.user)}
              data-tip={`<span dir="auto">${event.event.firstName} ${event.event.lastName}</span><br/><span dir="auto">${event.event.address}, ${event.event.city}</span><br/><span dir="auto">${event.event.phone}</span>`}
              data-for={event.event.id.toString()}
            />
            <TextAfterIcon
              isDay={event.isDay}
              style={{
                ...getNameStyle(event.event),
              }}
            >
              {event.event.firstName}&nbsp;
              {event.event.nickname && `(${event.event.nickname}) `}
              {event.event.lastName}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showDrivingPermit &&
        !isMobile &&
        typeof event.event.drivingPermitId === "string" &&
        event.event.drivingPermitId !== "6" ? (
          <IconsAndTextContainer>
            <Icon className="fa fa-id-card" />
            <TextAfterIcon>
              {
                drivingPermitsOptions.find(
                  (x) => x.value.toString() === event.event.drivingPermitId
                )?.label
              }
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showBalance ? (
          <IconsAndTextContainer onClick={() => setStudentBalance()}>
            <Icon className="icons8-scales" />
            {displayBalance && <TextAfterIcon>{studentProfile.studentBalanceWithFutureBalance}</TextAfterIcon>}
          </IconsAndTextContainer>
        ) : null}
        {event.showNotes && typeof event.event.notes === "string" ? (
          <IconsAndTextContainer
            data-tip={event.event.notes}
            onClick={() => event.openNotesChangeModal(event.event)}
            data-for={event.event.id.toString()}
          >
            <Icon className="icons8-comments" />
            {!isMobile && <TextAfterIcon>{event.event.notes}</TextAfterIcon>}
          </IconsAndTextContainer>
        ) : null}
        {event.showBranch && event.event.eventType !== 4 ? (
          <IconsAndTextContainer>
            <Icon className="fa fa-building" />
            <TextAfterIcon>{event.event.branchName}</TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showTeacher &&
        teachersOptions.length !== 0 &&
        event.event.teacherId !== null ? (
          <IconsAndTextContainer
            onClick={() => event.openTeacherChange(event.event)}
          >
            <Icon className="icons8-classroom" />
            <TextAfterIcon>
              {
                teachersOptions.find(
                  (x) => x.value.toString() === event.event.teacherId.toString()
                )?.label
              }
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
      </TitleContainer>
    </Container>
  ) : (
    <Container dir="auto">
      <ReactTooltip place="top" type="light" effect="float" html={true} />
      {isEditLessonModalOpen && (
        <EditEvent
          isNewAgreementModalOpen={isEditLessonModalOpen}
          setNewAgreementModalIsOpen={setIsEditLessonModalOpen}
          step={event.eventTime}
          selectedEvent={{
            ...event.event,
            name: event.event.name
              ? event.event.name
              : `${event.event.studentId} - ${event.event.firstName} ${event.event.lastName}`,
          }}
        />
      )}
      <TitleContainer dir="auto" className="eventView" isDay={event.isMargin}>
        {event.showEventStatus ? (
          <IconsAndTextContainer
            onClick={() => event.openEventStatusChangeModal(event.event)}
            style={{ margin: 0 }}
          >
            <Icon
              className={eventStatusIcons[event.event.eventStatus]}
              style={{ margin: 0 }}
              aria-hidden="true"
            />
          </IconsAndTextContainer>
        ) : null}
        {event.showTime ? (
          <IconsAndTextContainer onClick={() => setIsEditLessonModalOpen(true)}>
            <Icon className="icons8-clock" />
            <TextAfterIcon>
              {Math.round((event.event.end - event.event.start) / 1000 / 60)}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showEventType ? (
          <IconsAndTextContainer onClick={() => getStudentEventCount()}>
            {getEventTypeIcon(event.event.eventType)}
            <TextAfterIcon>
              {isShowCount ? studentProfile.totalEventsForType : event.event.title}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showBalance ? (
          <IconsAndTextContainer onClick={() => setStudentBalance()}>
            <Icon className="icons8-scales" />
            {displayBalance && <TextAfterIcon>{studentProfile.studentBalanceWithFutureBalance}</TextAfterIcon>}
          </IconsAndTextContainer>
        ) : null}
        {event.showNotes && typeof event.event.notes === "string" ? (
          <IconsAndTextContainer
            data-tip={event.event.notes}
            onClick={() => event.openNotesChangeModal(event.event)}
            data-for={event.event.id.toString()}
          >
            <Icon className="icons8-comments" />
            {!isMobile && <TextAfterIcon>{event.event.notes}</TextAfterIcon>}
          </IconsAndTextContainer>
        ) : null}
        {event.showVehicle && typeof event.event.description === "string" ? (
          <IconsAndTextContainer>
            <Icon
              className="icons8-car"
              data-tip={`<span dir="auto">${event.event.description}</span>`}
              data-for={event.event.id.toString()}
            />
            <TextAfterIcon>{event.event.description}</TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
      </TitleContainer>
      <div>
        {event.showStudent && typeof event.event.firstName === "string" ? (
          <IconsAndTextContainer
            // onClick={() => event.openStudentMenuModal(event.event)}
            onClick={() =>
              event.onStudentClick({
                event: event.event,
                value: event.event.studentId,
                label: `${event.event.firstName} ${event.event.lastName}`,
              })
            }
          >
            {/*<Icon
              className="icons8-user-group-man-man"
              data-tip={`<span dir="auto">${event.event.firstName} ${event.event.lastName}</span><br/><span dir="auto">${event.event.address}, ${event.event.city}</span><br/><span dir="auto">${event.event.phone}</span>`}
              data-for={event.event.id.toString()}
            />*/}
            <Icon
              className="icons8-whatsapp"
              onClick={() => sendWhatsAppEventMessage(event.event, event.event, event.user)}
              data-tip={`<span dir="auto">${event.event.firstName} ${event.event.lastName}</span><br/><span dir="auto">${event.event.address}, ${event.event.city}</span><br/><span dir="auto">${event.event.phone}</span>`}
              data-for={event.event.id.toString()}
            />
            <TextAfterIcon
              isDay={event.isDay}
              style={{
                ...getNameStyle(event.event),
              }}
            >
              {event.event.firstName}&nbsp;
              {event.event.nickname ? `(${event.event.nickname}) ` : null}
              {event.event.lastName}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showBranch && event.event.eventType !== 4 ? (
          <IconsAndTextContainer>
            <Icon className="fa fa-building" />
            <TextAfterIcon>{event.event.branchName}</TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showTeacher &&
        teachersOptions.length !== 0 &&
        event.event.teacherId !== null ? (
          <IconsAndTextContainer>
            <Icon className="icons8-classroom" />
            <TextAfterIcon>
              {
                teachersOptions.find(
                  (x) => x.value.toString() === event.event.teacherId.toString()
                )?.label
              }
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
      </div>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    showEventType: state.session.user.showEventType,
    showEventStatus: state.session.user.showEventStatus,
    showStudent: state.session.user.showStudent,
    showVehicle: state.session.user.showVehicle,
    showTime: state.session.user.showTime,
    eventTime: state.session.user.eventTime,
    showBalance: state.session.user.showBalance,
    showBranch: state.session.user.showBranch,
    showNotes: state.session.user.showNotes,
    showTeacher: state.session.user.showTeacher,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    user: state.session.user,
  };
}

export default connect(mapStateToProps, {
  getUsers,
  getAgreementDetails,
  getStudentEvents,
  getStudentEventCount,
  getStudentTextBooks,
  fetchTextBook,
  getStudentIncomes,
  getStudentData,
})(EventView);
