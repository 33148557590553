import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Icon, IconsAndTextContainer, TextAfterIcon, TitleContainer, Container, PopoverContainer, WheelIcon } from "./eventView.styles";
import { sendWhatsAppEventMessage, eventStatusIcons, getNameStyle, drivingPermitsOptions, getEventTypeIcon, isTestEvent, testStatus, internalTestStatus, isInternalTestEvent } from "../../../utils/events";
import useTeacheres from "../../useComponents/useTeachers";
import { Popover } from 'react-tiny-popover'
import { isMobile } from "react-device-detect";
import { set } from "lodash";
import { getStudent } from "../../../actions/studentesActions";


const EventViewVehicle = (props) => {
  const event = props.event;
  const [colors, setColors] = useState({background: props.chosenStudentColor, color: props.fontChosenStudentColor});
  const [showInfo, setShowInfo] = useState(false);
  const [displayBalance, setDisplayBalance] = useState(false);
  const [isShowCount, setIsShowCount] = useState(false);
  const [balance, setBalance] = useState(0);
  const [studentProfile, setStudentProfile] = useState(null);
  const teachersOptions = useTeacheres(
    props.isUsersLoaded,
    props.getUsers,
    props.users
  );

  const getEventTypeIcon = (eventType) => {
    switch (eventType) {
      case 1:
        return <Icon className="icons8-steering-wheel" style={{ margin: 0 }} />;
      case 2:
        return <Icon className="icons8-graduation-cap" />;
      case 3:
        return <Icon className="icons8-road" />;
      case 4:
        return <Icon className="icons8-tie" />;
      default:
        return "";
    }
  };

  const loadStudentProfile = async () => {
    const profile = await props.loadStudentProfile(event);
    setStudentProfile(profile)
  }

  const getStudentEventCount = () => {
    if (!isShowCount) {
      if (studentProfile === null) {
        loadStudentProfile().then(() => {
          setIsShowCount(true);
        });
      } else {
        setIsShowCount(true);
      }
    } else {
      setIsShowCount(false);
    }
  };

  const setStudentBalance = () => {
    if (displayBalance) {
      setDisplayBalance(false);
    } else {
      loadStudentProfile().then(() => {
        setDisplayBalance(true);
      });
    }
  };
  const openStudent = async () => {
    if (props.updateStudent == 0) return;
    const studentData = await props.getStudent(event.studentId);
    props.history.push("/updateStudent", { studentData: studentData[0] });
  };
  // const vehicle = props.vehicles.find((v) => v.id == props.event.vehicleId);
  // const licenseType = props.licenseTypes.find((v) => v.id == vehicle ? vehicle.licenseType : 0);
  // return (<><span>#{vehicle ? vehicle.vehicleNumber : '0000000'} {vehicle ? vehicle.description : '-'}</span>: <span>{licenseType ? licenseType.name : '-'}</span></>);
  const infoContent = () => {
    const eventStatus = props.showEventStatus ? (
      <IconsAndTextContainer className="grid-icon-item"
        onClick={() => props.openEventStatusChangeModal(props.event)}
        style={{ margin: 0 }}
      >
        <Icon
          className={eventStatusIcons[props.event.eventStatus]}
          style={{ margin: 0 }}
          aria-hidden="true"
        />
      </IconsAndTextContainer>
    ) : null;
    const showStudent = props.showStudent && typeof props.event.firstName === "string" ? (
      <IconsAndTextContainer className="grid-icon-item">
        <Icon
          className="icons8-whatsapp"
          onClick={() => sendWhatsAppEventMessage(event, event, props.user)}
          data-tip={`<span dir="auto">${event.firstName} ${event.lastName}</span><br/><span dir="auto">${event.address}, ${event.city}</span><br/><span dir="auto">${event.phone}</span>`}
          data-for={event.id.toString()}
        />
        </IconsAndTextContainer>
    ) : null;
    const showTime = props.showTime ? (
      <IconsAndTextContainer className="grid-icon-item" onClick={() => props.openeUpdateLessonModal({...event, name: event.name
        ? event.name
        : `${event.studentId} - ${event.firstName} ${event.lastName}`})}>
        <Icon className="icons8-clock" />
        <TextAfterIcon>
          {Math.round((event.end - event.start) / 1000 / 60)}
        </TextAfterIcon>
      </IconsAndTextContainer>
    ) : null;
    const showType = props.showEventType ? (
      <IconsAndTextContainer className="grid-icon-item" onClick={() => getStudentEventCount()}>
        {getEventTypeIcon(event.eventType)}
        <TextAfterIcon>
          {isShowCount ? studentProfile.totalEventsForType : event.title}
        </TextAfterIcon>
      </IconsAndTextContainer>
    ) : null;
    const showVehicle = props.showVehicle && !isMobile && typeof event.description === "string" ? (
      <IconsAndTextContainer className="grid-icon-item"
        onClick={() => props.openVehicleChange(event)}
      >
        <Icon
          className="icons8-car"
          data-tip={`<span dir="auto">${event.description}</span>`}
          data-for={event.id.toString()}
        />
        <TextAfterIcon>{event.description}</TextAfterIcon>
      </IconsAndTextContainer>
    ) : null;
    const showPickupLocationName = typeof event.pickupLocationName === "string" &&
    event.pickupLocationName !== "ללא" ? (
      <IconsAndTextContainer className="grid-icon-item"
        onClick={() => props.openPickupModal(event.id)}
      >
        <Icon
          className="icons8-location"
          data-tip={`<span dir="auto">${event.pickupLocationName}</span>`}
          data-for={event.id.toString()}
        />
        <TextAfterIcon>{event.pickupLocationName}</TextAfterIcon>
      </IconsAndTextContainer>
    ) : null;
    const showDrivingPermit = props.showDrivingPermit && !isMobile && typeof event.drivingPermitId === "string" && event.drivingPermitId !== "6" ? (
      <IconsAndTextContainer className="grid-icon-item">
        <Icon className="fa fa-id-card" />
        <TextAfterIcon>
          {
            drivingPermitsOptions.find(
              (x) => x.value.toString() === event.drivingPermitId
            )?.label
          }
        </TextAfterIcon>
      </IconsAndTextContainer>
    ) : null;
    const showBalance = props.showBalance ? (
      <IconsAndTextContainer className="grid-icon-item" onClick={() => setStudentBalance()}>
        <Icon className="icons8-scales" />
        {displayBalance && <TextAfterIcon>{studentProfile.studentBalanceWithFutureBalance}</TextAfterIcon>}
      </IconsAndTextContainer>
    ) : null;
    const showNotes = props.showNotes && typeof event.notes === "string" ? (
      <IconsAndTextContainer className="grid-icon-item"
        data-tip={event.notes}
        onClick={() => props.openNotesChangeModal(event)}
        data-for={event.id.toString()}
      >
        <Icon className="icons8-comments" />
        {!isMobile && <TextAfterIcon>{event.notes}</TextAfterIcon>}
      </IconsAndTextContainer>
    ) : null;
    const showBranch = props.showBranch && event.eventType !== 4 ? (
      <IconsAndTextContainer className="grid-icon-item">
        <Icon className="fa fa-building" />
        <TextAfterIcon>{event.branchName}</TextAfterIcon>
      </IconsAndTextContainer>
    ) : null;
    const showTeacher = props.showTeacher && teachersOptions.length !== 0 && event.teacherId !== null ? (
        <IconsAndTextContainer className="grid-icon-item"
          onClick={() => props.openTeacherChange(event)}
        >
          <Icon className="icons8-classroom" />
          <TextAfterIcon>
            {
              teachersOptions.find(
                (x) => x.value.toString() === event.teacherId.toString()
              )?.label
            }
          </TextAfterIcon>
        </IconsAndTextContainer>
      ) : null;
      const callPhone = props.showStudent ? <IconsAndTextContainer className="grid-icon-item">
        <a href={"tel:"+event.phone}>
          <WheelIcon src="/icons/call-phone.svg" />
        </a>
      </IconsAndTextContainer>
      : null;
      const paymentForm = props.createStudentIncome ? <IconsAndTextContainer className="grid-icon-item" onClick={() => props.openNewChargeModalOpen(event)}>
          <WheelIcon src="/icons/payment.svg" />
      </IconsAndTextContainer>
      : null;
      const studentPage = props.updateStudent > 0 ? <IconsAndTextContainer className="grid-icon-item" onClick={() => openStudent(event)}>
          <WheelIcon src="/icons/study-black.svg" />
      </IconsAndTextContainer>
      : null;
    return (
      <PopoverContainer>
        <div className="student-name">{event.firstName} {event.lastName}</div>
        <hr/>
        <div className="grid-container">
          {eventStatus}
          {showTime}
          {showType}
          {showVehicle}
          {showPickupLocationName}
          {callPhone}
          {showStudent}
          {showDrivingPermit}
          {showBalance}
          {showNotes}
          {showBranch}
          {showTeacher}
          {paymentForm}
          {studentPage}
        </div>
        <div className="student-name">
          <hr/>
          {event.studentNotes}
        </div>
      </PopoverContainer>
      );
  }
  const iconColor = () => {
    const green = "rgb(82 175 88)";
    const red = "rgb(222 0 0)";
    const yellow = "rgb(214 240 2)";
    const purple = "rgb(140 2 240)";
    const isNoties = event.notes && event.notes.length > 0;
    const isPickUp = !!event.pickupLocationAddress
    if (isNoties && isPickUp) return purple;
    if (isPickUp) return yellow;
    if (isNoties) return red;
    return green;
  }
  const getIcon = () => {
    if (isTestEvent(event)) return 'fa-solid fa-fw '+testStatus[event.testStatus].icon;
    if (isInternalTestEvent(event)) return 'fa-solid fa-fw '+internalTestStatus[event.internalTestStatus].icon;
    return 'fa-solid fa-fw fa-info-circle';
  }
  return (
    <Container
      chosenStudentColor={colors.background}
      fontChosenStudentColor={colors.color}
      isStudentChosen={
        typeof props.chosenStudentId !== "undefined" &&
        props.chosenStudentId == event.studentId
      }
    >
      <TitleContainer>
          {/* {props.showEventStatus ? (
            <IconsAndTextContainer
              onClick={() => props.openEventStatusChangeModal(props.event)}
              style={{ margin: 0 }}
            >
              <Icon
                className={eventStatusIcons[props.event.eventStatus]}
                style={{ margin: 0 }}
                aria-hidden="true"
              />
            </IconsAndTextContainer>
          ) : null}
          {props.showStudent && typeof props.event.firstName === "string" ? (
              <Icon
                className="icons8-whatsapp"
                onClick={() => sendWhatsAppEventMessage(event, event, props.user)}
                data-tip={`<span dir="auto">${event.firstName} ${event.lastName}</span><br/><span dir="auto">${event.address}, ${event.city}</span><br/><span dir="auto">${event.phone}</span>`}
                data-for={event.id.toString()}
              />
          ) : null} */}
          <IconsAndTextContainer>
            <Popover 
              onClickOutside={() => {setShowInfo(false)}}
              containerStyle={{ zIndex: 9 }}
              isOpen={showInfo}
              padding={10}
              positions={['bottom', 'left']}
              content={({ position, nudgedLeft, nudgedTop }) => (infoContent())}>
              <Icon className={getIcon()} style={{ color:  iconColor(), cursor: "pointer" }} onClick={() => setShowInfo(!showInfo)} />
            </Popover>
            <TextAfterIcon
              onClick={() =>
                props.onStudentClick({
                  event: event,
                  value: event.studentId,
                  label: `${event.firstName} ${event.lastName}`,
                })
              }
              isDay={props.isDay}
              style={{
                ...getNameStyle(event),
              }}
            >
              {event.firstName}&nbsp;
              {event.nickname && `(${event.nickname}) `}
              {event.lastName}
            </TextAfterIcon>
            </IconsAndTextContainer>
          </TitleContainer>
        </Container>);
}

function mapStateToProps(state) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    licenseTypes: state.LicenseTypesReducer.licenseTypes,
    chosenStudentColor: state.session.user.chosenStudentColor,
    fontChosenStudentColor: state.session.user.fontChosenStudentColor,    
    showEventType: state.session.user.showEventType,
    showEventStatus: state.session.user.showEventStatus,
    showStudent: state.session.user.showStudent,
    showVehicle: state.session.user.showVehicle,
    showTime: state.session.user.showTime,
    eventTime: state.session.user.eventTime,
    showBalance: state.session.user.showBalance,
    showBranch: state.session.user.showBranch,
    showNotes: state.session.user.showNotes,
    showTeacher: state.session.user.showTeacher,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    user: state.session.user,
    updateStudent: state.session.user.updateStudent,
    createStudentIncome: state.session.user.createStudentIncome,
  };
}

export default withRouter(connect(mapStateToProps, {getStudent})(EventViewVehicle));
